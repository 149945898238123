import * as React from 'react';
import Layout from "../../../Components/Layout"
import { FooterComponent } from '../../../Components/FooterComponent';
import ReceptionIndex from '../../../modules/ReceptionSuite/ReceptionIndex';
import HeaderComponent from '../../../Components/HeaderComponent';
import { RECEPTION_PRICING_PAGE } from '../../../constant/routes';
import Seo from '../../../Components/Seo';

const index = () => {
  return (
    <>
       <Seo title="Reception Management Suite | Webbermill"/>
      <Layout name="reception"  hasProducts={true}>
        <title>Visitor Management Suite | Home</title>
        <HeaderComponent hasPrice priceUrl={RECEPTION_PRICING_PAGE} loginUrl="/login"/>
        <ReceptionIndex  />
        <FooterComponent />
      </Layout>
    </>
  )
}

export default index