import * as React from 'react'
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";


const BannderSlider = () => {

  const NextArrow = ({ className, style, onClick }) => {
    
    return (
      <FaChevronRight className={className}
      style={{ ...style, display: "block", color: '#9B9999' }}
      onClick={onClick} />
      
    );
  }

  const PrevArrow = ({ className, style, onClick }) => {
    
    return (
      <FaChevronLeft className={className}
      style={{ ...style, display: "block", color: '#9B9999' }}
      onClick={onClick} />
      
    );
  }

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    speed: 2000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };
    return (
        <div className='slick-carousel'>
        <Slider {...settings}>
          <div>
            <img src="/tewaa_assets/Screenshot.png" width="461"height="312" alt='bannerSlider' />
          </div>
          <div>
          <img src="/tewaa_assets/dashboard.png" width="461"height="312" alt='bannerSlider'/>
          </div>
          <div>
          <img src="/tewaa_assets/table.png" width="461"height="312" alt='bannerSlider'/>
          </div>
          <div>
          <img src="/tewaa_assets/visitor.png" width="461"height="312" alt='bannerSlider'/>
          </div>
        </Slider>
      </div>
    )
}

export default BannderSlider