import React from 'react'
import BannderSlider from '../../Components/BannerSlider'
import { CustomButton, CustomLinkBtn } from '../../Components/CustomButton'
import { Card } from "react-bootstrap"
import { Link } from 'gatsby'
import { PlayButtonIcon } from '../../Components/SVGs'
import {Tab, Nav} from 'react-bootstrap'

const CustomCard = ({ children, url }) => {
    return (
        <>
        <Link to={`${url}`}>
            <Card>
                <Card.Body>{children}</Card.Body>
            </Card>
        </Link>
        </>
    )
}



const ReceptionIndex = () => {
  return (
    <>
         <section className="reception__content">
               <div className="reception__container">
                  <div className='reception__container-banner container'>
                  <div className="reception__container-text">
                     <h6>VISITOR MANAGEMENT SUITE</h6>
                    
                     <h3>Make lasting impression on <br /> visitors</h3>
                     <p className="mt-4">Software development is the process of conceiving, <br /> specifying, designing, programming, documenting.</p>
                     <div className="reception__container-btn">
                       <CustomButton text="Get Started" />
                       <CustomButton text="Contact" />
                      
                     </div>
                  </div>
                  <div className="reception__container-carousel">
                     <div  className="laptop_mockup">
                       <img src="/tewaa_assets/laptop2.png" width="400" alt="receptionIcon"/>
                     </div>
                     <BannderSlider />
                  </div>
                  </div>
               </div>
               <div className="reception__customers">
             <div className="reception__customers-title">
                <h6>Used By</h6>
             </div>
               <div className="reception__customers-logos">
               <div class="container">
                  <div class="row">
                     <div class="col-sm">
                     <CustomCard >
                        <img src="/customers_logo/she.png"  width="80" alt="receptionIcon" />
                     </CustomCard>
                     </div>
                     <div class="col-sm">      
                        <CustomCard>
                        <img src="/customers_logo/netflix.png" width="80" height="30" alt="receptionIcon"/>
                        </CustomCard>
                     </div>
                     <div class="col-sm">
                     <CustomCard>
                     <img src="/customers_logo/aok.png" width="70" alt="receptionIcon"/>
                     </CustomCard>
                     </div>
                     <div class="col-sm">
                     <CustomCard>
                     <img src="/customers_logo/cropped.png" width="100" height="30" alt="receptionIcon"/>
                     </CustomCard>
                     </div>
                     <div class="col-sm">
                     <CustomCard>
                     <img src="/customers_logo/invideo.png" width="80" alt="receptionIcon"/>
                     </CustomCard>
                     </div>
                     <div class="col-sm">
                     <CustomCard>
                     <img src="/customers_logo/vida.png" width="80" alt="receptionIcon"/>
                     </CustomCard>
                     </div>
                     <div class="col-sm">
                     <CustomCard>
                  <img src="/customers_logo/cnet.png" width="30" alt="receptionIcon"/>
                  </CustomCard>
                     </div>
                  </div>
                  </div>
             </div>
            </div>
            <div className="container">
                   <div className="reception__suite">
                     <div className="reception__suite-wrapper frame">
                         {/* <div className="zoomout ">
                         <img src="/tewaa_assets/Icons/anna.jpg" alt=""/>
                         </div> */}
                     <CustomCard> 
                         {/* <AiOutlinePlayCircle /> */}

                         <div className="playIcon">
                         <PlayButtonIcon />
                         </div>
                         </CustomCard>    
                     </div> 
                     <div className="reception__suite-text">
                        <h3>See how our front-desk suite transform your visitors experience </h3>
                        <p>Managing visitors should be simple and memorable without making changes to <br /> already existing work-flow. <br /> Through features that are loved by everyone we double your security and brand value.</p>
                        <button href="" >Watch Now</button>
                     </div>
                   </div>
                </div>
                <div className="container reception__choice">
            <div class="container">
            <div class="row text-center">
                  <div class="reception__choice-text text-center">
                    <h3>Why choose Tewaa Front-Desk Suite</h3>
                    <p>Tewaa front-desk suite impacts your business in many ways from security to making impression on your  customers, we provide your company with features that already do what you want to do.</p>
                  </div>
               </div>
               <div class="row py-4 mt-2">
                  <div class="col">
                     <CustomCard>
                        <div className="reception__choice-logo-1">
                           <img src="/tewaa_assets/Icons/Cloud.svg"  width="35" height="35" alt="receptionIcon"/>
                        </div>
                        <div  className="reception__choice-text-1">
                           <h4>Cloud base <br/> Archives</h4>
                           <p>Store paperless visitor logs for long term and produce various list (visitor(s) history, time spent per every visit, total visit per week or month or year).</p>
                        </div>
                     </CustomCard>
                  </div>
                  <div class="col">
                    <CustomCard>
                      <div className="reception__choice-logo-1">
                           <img src="/tewaa_assets/Icons/location.svg" width="30" height="30" alt="receptionIcon"/>
                        </div>
                        <div  className="reception__choice-text-1">
                           <h4>Location <br/> Management</h4>
                           <p>Decentralize the use and centrally manage your company visitors data that inform your decision on productivity and efficiency.</p>
                        </div>
                     </CustomCard>
                  </div>
                  <div class="col">
                  <CustomCard>
                      <div className="reception__choice-logo-1">
                           <img src="/tewaa_assets/Icons/badge.svg" width="30" height="30" alt="receptionIcon"/>
                        </div>
                        <div  className="reception__choice-text-1">
                           <h4>Design Custom <br/> Badges</h4>
                           <p>Print each visitor’s badge with unique ID information like name, purpose, login time, and host or department.</p>
                        </div>
                  </CustomCard>
                  </div>
               </div>
               <div class="row">
                  <div class="col">
                  <CustomCard>
                    <div className="reception__choice-logo-1">
                        <img src="/tewaa_assets/Icons/lock.svg"  width="30" height="30" alt="receptionIcon"/>
                     </div>
                     <div  className="reception__choice-text-1">
                        <h4>Hightened <br/> Security</h4>
                        <p>Through NDA, two way verification and secure cloud platform and blacklist are additional layers of security to your business.</p>
                     </div>
                  </CustomCard>
                  </div>
                  <div class="col">
                  <CustomCard>
                     <div className="reception__choice-logo-1">
                        <img src="/tewaa_assets/Icons/headset.svg"  width="30" height="30" alt="receptionIcon"/>
                     </div>
                     <div  className="reception__choice-text-1">
                        <h4>Easy Enquiry <br/> Management</h4>
                        <p>Easily integrate your enquiries from your website into tewaa front-desk and  convert prospective clients into paying customers</p>
                     </div> 
                  </CustomCard>
                  </div>
                  <div class="col">
                  <CustomCard>
                  <div className="reception__choice-logo-1">
                        <img src="/tewaa_assets/Icons/analytics.svg"  width="30" height="30" alt="receptionIcon"/>
                     </div>
                     <div  className="reception__choice-text-1">
                        <h4>Smart Report <br/> Generation</h4>
                        <p>Generate reports in minutes and choose file type to export or download or print. Further select columns and items to reflect on the report.</p>
                     </div> 
                  </CustomCard>
                  </div>
               </div>
               </div>
            </div>
            <div className="reception__bg">
               <div className="reception__bg-blob">
                  <img src="/tewaa_assets/Icons/Blob.svg" alt="receptionIcon"/>
               </div>
               <div className="reception__features container">
               <div className="row ">
               <div className="col reception__features-text">
                 <h4>Everything you need to transform your reception</h4>
                 <p>We just don't provide your company with features but present to your organization a product that embody <br /> real life happenings and built with the sole intension to give the reception a modern experience.</p>
               </div>
               </div>
               <div className="reception__features-content">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first" transition={false}>
                 <div className="reception__features-content-text">
                  <Nav variant="pills" className="flex-column">
                     <Nav.Item>
                        <Nav.Link eventKey="first">
                           <span><img src="/tewaa_assets/Icons/cloud.svg" width="20" height="20" alt="tabIcon"/> </span> <span className="cloud">Visitor Management</span>
                        </Nav.Link>
                     </Nav.Item>
                     <Nav.Item>
                        <Nav.Link eventKey="second">
                           <span><img src="/tewaa_assets/Icons/search.svg"  width="20" height="20" alt="tabIcon"/> </span> <span className="location">Document Management</span>
                        </Nav.Link>
                     </Nav.Item>
                     <Nav.Item>
                        <Nav.Link eventKey="third">
                        <span><img src="/tewaa_assets/Icons/coins.svg"  width="20" height="20" alt="tabIcon"/> </span> <span className="design">Delivery Management</span>
                        </Nav.Link>
                     </Nav.Item>
                     <Nav.Item>
                        <Nav.Link eventKey="fourth">
                           <span><img src="/tewaa_assets/Icons/simple.svg"  width="20" height="20" alt="tabIcon"/> </span> <span className="enquiry">Enquiry Management</span>
                        </Nav.Link>
                     </Nav.Item>
                     <Nav.Item>
                        <Nav.Link eventKey="fifth">
                          <span><img src="/tewaa_assets/Icons/lock2.svg"  width="20" height="20" alt="tabIcon"/> </span> <span className="security">Schedule Task</span>
                        </Nav.Link>
                     </Nav.Item>
                   </Nav>
                 </div>
                 <div className="reception__features-content-img1">
                    <img src="/tewaa_assets/Icons/grid.svg" alt="receptionIcon" />
                 </div>
                 <div className="reception__features-content-img2">
                    <Tab.Content>
                     <Tab.Pane eventKey="first">
                        <img src="/tewaa_assets/Icons/visitor.png" width="400" alt="receptionIcon"/>
                     </Tab.Pane>
                     <Tab.Pane eventKey="second">
                        <img src="/tewaa_assets/Icons/document.png" width="400" alt="receptionIcon"/>
                     </Tab.Pane>
                     <Tab.Pane eventKey="third">
                        <img src="/tewaa_assets/Icons/delivery.png" width="400" alt="receptionIcon"/>
                     </Tab.Pane>
                     <Tab.Pane eventKey="fourth">
                        <img src="/tewaa_assets/Icons/enquiry.png" width="400" alt="receptionIcon"/>
                     </Tab.Pane>
                     <Tab.Pane eventKey="fifth">
                        <img src="/tewaa_assets/Icons/task.png" width="400" alt="receptionIcon"/>
                     </Tab.Pane>
                     </Tab.Content>
                 </div>
               </Tab.Container>
               </div>
               </div>
            </div>
            <div className="reception__services container">
              <div className="reception__services-title">
                 <h4>Industries we serve</h4>
              </div>
              <div className="reception__services-icons">
                  <div className="box text-center">
                     <div class="overlay"></div>
                     <span>
                        <img src="/tewaa_assets/Icons/wifi.svg" width="25" alt="receptionIcon"/>
                     </span>
                     <p>Communication</p>  
                  </div>
                  <div className="box text-center">
                  <div class="overlay"></div>
                     <span>
                        <img src="/tewaa_assets/Icons/health.svg" alt="receptionIcon"/>
                     </span>
                     <p>Health</p>
                  </div>
                  <div className="box text-center">
                  <div class="overlay"></div>
                     <span>
                        <img src="/tewaa_assets/Icons/umbrella.svg" alt="receptionIcon"/>
                     </span>
                     <p>Insurance</p>
                  </div>
                  <div className="box text-center">
                  <div class="overlay"></div>
                     <span>
                        <img src="/tewaa_assets/Icons/stack.svg" alt="receptionIcon"/>
                     </span>
                     <p>Technology</p>
                  </div>
                  <div className="box text-center">
                  <div class="overlay"></div>
                  <span>
                        <img src="/tewaa_assets/Icons/agric.svg" alt="receptionIcon"/>
                     </span>
                     <p>Finance</p>
                  </div>
                  <div className="box text-center">
                  <div class="overlay"></div>
                    <span>
                        <img src="/tewaa_assets/Icons/education.svg" width="30" alt="receptionIcon"/>
                     </span>
                     <p>Education</p>
                  </div>
                  <div className="box text-center">
                  <div class="overlay"></div>
                     <span>
                        <img src="/tewaa_assets/Icons/ngo.svg" width="20" alt="receptionIcon"/>
                     </span>
                     <p>NGO`s</p>
                  </div>
                  <div className="box text-center">
                  <div class="overlay"></div>
                     <span>
                        <img src="/tewaa_assets/Icons/agric.svg" alt="receptionIcon"/>
                     </span>
                     <p>Agriculture</p>
                  </div>
              </div>
            </div>
            <div className="container  reception__testimonial">
              <div className="reception__testimonial-text">
                <h6>"Tewaa reception have transform our first point of <br /> contact with our customers"</h6>
                <i>Thomas Jefferson Gates</i>
                <p className="mt-1">CEO, Western Premier Ventures</p>
                <span><img src="/tewaa_assets/Icons/star.svg" width="40" alt="receptionIcon"/></span> <br />
                <img src="/tewaa_assets/Icons/signature.svg" className="mt-4" width="100" alt="receptionIcon"/>
                {/* <div className="link">
                <CustomLinkButton href="#">View All Testimonials</CustomLinkButton>
                </div> */}
              </div>
              <div className="reception__testimonial-img">
              <div className="playIcon">
              <PlayButtonIcon />
               </div>
                <div className="overlay"></div>
                <img src="/tewaa_assets/Icons/group.jpg" alt="receptionIcon"/>
              </div>
            </div>
            <div className="reception__browse text-center">
            <CustomLinkBtn to='' text='Browse our plan for this software' alt="receptionIcon"/>
            </div>
            </section>
    </>
  )
}

export default ReceptionIndex